$(function () {
    let screenWidth = $(window).width();

    $(".js-burger").click(function(){ 
        $(this).toggleClass("active");
        
        $(".js-menu").toggleClass("active");
        $("body").toggleClass("open");

    })
});