
$(function () {
    let screenWidth = $(window).width();

    if (screenWidth < 1024) {
        $(".js-tooltips").addClass("owl-carousel");
    }
    else {
        $(".js-tooltips__item").on("mouseover", function() {
            $(".js-tooltips__item").removeClass("active");
            $(this).addClass("active");
        })
    }

    if (screenWidth < 1024) {
        startCarousel();
    }
    else {
        $('.owl-carousel').addClass('off');
    }
});
$(window).resize(function () {
    let screenWidth = $(window).width();

    if (screenWidth < 1024) {
        startCarousel();
    } else {
        stopCarousel();
    }
});

function startCarousel() {
    $(".js-tooltips").addClass("owl-carousel");
    $(".js-tooltips").owlCarousel({
        autoplay: false,
        autoplaySpeed: 300,
        autoplayTimeout: 1000000,
        autoplayHoverPause: true,
        dragEndSpeed: 300,
        dotsSpeed: 300,
        navSpeed: 300,
        loop: false,
        nav: true,
        navText: ["<div class='nav-btn prev'></div>", "<div class='nav-btn next'></div>"],
        touchDrag: true,
        dots: true,
        items: 1,
        margin: 0,
    });

}
function stopCarousel() {
    var owl = $('.owl-carousel');
    owl.trigger('destroy.owl.carousel');
    owl.addClass('off');
    $(".js-tooltips").removeClass("owl-carousel");

}