$(function () {
    let screenWidth = $(window).width();

    if (screenWidth < 768) {
        startCarousel();
    }
    else {
        $('.owl-carousel').addClass('off');
    }
});
$(window).resize(function () {
    let screenWidth = $(window).width();

    if (screenWidth < 768) {
        startCarousel();
    } else {
        stopCarousel();
    }
});

function startCarousel() {
    $(".js-news").addClass("owl-carousel");
    $(".js-news").owlCarousel({
        margin: 20,
        loop: false,
        autoWidth: true,
        nav: false,
        dots: false,
        items: 1
    });

}
function stopCarousel() {
    var owl = $('.owl-carousel');
    owl.trigger('destroy.owl.carousel');
    owl.addClass('off');
    $(".js-news").removeClass("owl-carousel");

}