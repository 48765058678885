$(function () {
    let screenWidth = $(window).width();
    if(screenWidth < 768) {
        activePage();
    }
    else {
        $(document).find(".clone").remove();
    }
});
$(window).resize(function () {
    let screenWidth = $(window).width();

    if(screenWidth < 768) {
        activePage();
    }
    else {
        $(document).find(".clone").remove();
    }
});
function activePage() {
    if($(".clone").length < 1) {
        var clone = $(document).find(".js-menu").find(".nav__item.active");
        clone.clone().prependTo( ".header__row" );
        $(document).find(".header__row .header__logo").siblings(".nav__item.active").addClass("clone");
        $(document).find(".clone").removeClass("nav__item");
    }
    
}